





















import axios from 'axios';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import JournalReportSettings, { ReportSettings } from '@/components/reports/JournalReportSettings.vue';
import { InactivityWatcher } from '@/inactivityWatcher';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

import { BaseVue } from '../../BaseVue';

@Component({
  components: {
    JournalReportSettings,
  },
})
export default class JournalReportNew extends BaseVue {
  @Prop({ required: true })
  journalReportType!: 'JournalReport' | 'RolledUpJournalReport';

  public reportSettings: ReportSettings = {
    startDate: null,
    endDate: null,
    connectionId: null,
  };

  public reportLoading = false;
  public hasError = false;
  public errors: string[] = [];
  public reportDoc: any = null;

  public inactivityWatcherKeepActive?: any | null = null;
  public reportElapsedTime?: string = '';

  public onSettingsUpdate(settings: ReportSettings) {
    this.reportSettings = settings;
  }

  public frameLoaded() {
    this.reportLoading = false;
    requestParentToKeepActive('report', false);
    this.inactivityWatcherKeepActive?.dispose();
  }

  public onRunReport() {
    this.hasError = false;
    this.errors = [];
    this.reportLoading = true;
    this.reportDoc = null;
    this.reportElapsedTime = undefined;

    const orgId = this.$store.state.currentOrg.id;
    const { startDate, endDate, connectionId, groupBy } = this.reportSettings;

    const reportStartTime = Date.now();

    let reportUrl =
      this.getReportApiUrl() +
      `reports/view?type=${this.journalReportType}&orgId=${orgId}&connectionId=${connectionId}`;

    if (startDate) {
      reportUrl += `&startDate=${startDate}`;
    }

    if (endDate) {
      reportUrl += `&endDate=${endDate}`;
    }

    if (groupBy) {
      reportUrl += `&groupBy=${groupBy}`;
    }

    this.fetchReport(reportUrl).then((res) => {
      this.reportDoc = res;
      this.reportElapsedTime = this.getElapsedTime(reportStartTime);
    });

    requestParentToKeepActive('report', true);
    this.inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
      () => this.reportLoading && document.contains(this.$el)
    );
  }

  private async fetchReport(reportUrl: string) {
    try {
      const response = await axios.get(reportUrl, { withCredentials: true });
      const data = response.data;
      return data;
    } catch (error) {
      console.error('Could not fetch Journal Entry Report', error);
      this.hasError = true;
      this.errors.push('An unexpected error occured while trying to run Journal Entry Report.');
      return null;
    }
  }
}
