







import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

@Component({
  name: 'ReportHTMLToCSV',
})
export default class ReportHTMLToCSV extends BaseVue {
  @Prop({ required: true })
  public readonly reportDoc!: string;

  @Prop({ required: true })
  public readonly fileName!: string;

  @Prop({ default: () => [] })
  public readonly repeatIndexes!: number[];

  @Prop({ default: false })
  public readonly removeSubtotal!: boolean;

  @Prop()
  public readonly subtotalIndex?: number;

  @Prop({ default: false })
  public readonly changeReporttotal!: boolean;

  @Prop()
  public readonly reporttotalIndex?: number;

  async tableToCSV() {
    const repeatFields = new Map<number, string>(this.repeatIndexes.map((x) => [x, '']));
    const doc = this.parseReport();
    const csvData = [] as string[];
    if (!doc) {
      return;
    }
    // Get each row data
    const rows = doc.querySelectorAll('tr');

    for (let i = 0; i < rows.length; i++) {
      // Get each column data
      const cols = rows[i].querySelectorAll('td,th');

      const csvrow = [] as string[];
      let skip = false;
      for (let j = 0; j < cols.length; j++) {
        let colVal = (cols[j] as HTMLElement).innerText.replace(/(\r\n|\n|\r)/gm, '');
        if (this.removeSubtotal && j === this.subtotalIndex && colVal === 'Subtotal') {
          skip = true;
          continue;
        }
        if (this.changeReporttotal && j === this.reporttotalIndex && colVal === 'Report Total') {
          colVal = 'Report Total: Total Change to Bitwave - Digital Assets';
        }
        if (rows[i].classList.contains('content-row') && repeatFields.has(j)) {
          if (colVal) {
            repeatFields.set(j, colVal);
          } else {
            colVal = repeatFields.get(j) ?? '';
          }
        }
        // Get the text data of each cell of a row and push it to csvrow
        csvrow.push(`"${colVal}"`);
      }
      if (skip) continue;
      csvData.push(csvrow.join(','));
    }
    // combine each row data with new line character
    const csvDataResult = csvData.join('\n');

    this.downloadCSVFile(csvDataResult);
  }

  parseReport(): Document {
    const parser = new DOMParser();

    // Parse the text from reportSvc
    const doc = parser.parseFromString(this.reportDoc, 'text/html');
    return doc;
  }

  downloadCSVFile(csvData: string) {
    // Create CSV file object and feed our
    // csv_data into it
    const CSVFile = new Blob([csvData], { type: 'text/csv' });

    // Create to temporary link to initiate
    // download process
    const link = document.createElement('a');

    // Download csv file
    link.download = `${this.fileName}.csv`;
    var url = window.URL.createObjectURL(CSVFile);
    link.href = url;

    // This link should not be displayed
    link.style.display = 'none';
    document.body.appendChild(link);

    // Automatically click the link to trigger download
    link.click();
    // Remove the link
    document.body.removeChild(link);
  }
}
